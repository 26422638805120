import React from 'react';
import * as styles from './brandbar.module.scss';
const CuraleafLogo = require('../../../static/svg/logo.inline.svg');
const SelectLogo = require('../../../static/svg/selectlogomark_black.inline.svg')
const ExternalLink = require('../../../static/svg/new-window-icon.inline.svg');

class BrandBar extends React.Component<{}>{

  render() {
    return (
      <div className={styles.brandbarModule}>
        <a href="https://curaleaf.com" className={styles.brandbarModuleLink}>
          <CuraleafLogo style={{ height: '18px', width: '18px', marginTop: '-5px', marginRight: '5px' }}/>
          Curaleaf
        </a>
        <a href="https://selectbetter.com" className={styles.brandbarModuleLink} style={{ backgroundColor: '#facd00', color: '#000' }}>
          <SelectLogo style={{ height: '18px', width: '18px', marginTop: '-5px', marginRight: '5px' }}/>
          Select
        </a>
        <a href="https://www.grassrootscannabis.com/our-products/" className={styles.brandbarModuleLink} target="_blank">
          Grassroots
          <ExternalLink style={{ height: '14px', width: '14px', marginLeft: '5px', marginTop: '-5px' }}/>
        </a>
        <a href="https://curaleafhemp.com/" className={styles.brandbarModuleLink} target="_blank">
          Hemp
          <ExternalLink style={{ height: '14px', width: '14px', marginLeft: '5px', marginTop: '-5px' }}/>
        </a>
      </div>
      );
  }
}

export default BrandBar;
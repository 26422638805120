import PropTypes from 'prop-types';

export const headerDefaultProps = {
  className: null,
};

const headerTypes = {
  className: PropTypes.string,
};

export default headerTypes;

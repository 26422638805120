
enum eventDictionary {
    'selectBetterButtonClick',
    'selectBetterProductsButtonClick',
    'findUsButtonClick',
    'stayUpdatedFormSubmit'
}

export {
    eventDictionary
};

import { eventDictionary } from './eventDictionary';

const eventTrigger = (eventName: eventDictionary & number, firstOnly = false): undefined =>
{
    pushEvent(eventDictionary[eventName], firstOnly);
    return;
}

const redirectEvent = (eventName: number, url: string, target: string = '_self'): undefined =>
{
    pushEvent(eventDictionary[eventName]);
    window.open(url, target);
    return;
}

const pushEvent = (eventName: string, firstOnly = false) =>
{
    if (typeof window === "undefined") return;
    const windowRef: { dataLayer: any[]} = window as any;

    windowRef.dataLayer = (window as any).dataLayer || [];

    if (firstOnly && windowRef.dataLayer.find((data) => data.event === eventName)) return;

    windowRef.dataLayer.push({ event: eventName });
}

export default { eventTrigger, redirectEvent }

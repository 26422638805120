import React, { Fragment } from 'react';
import { navigate } from 'gatsby';

import classNames from 'classnames';
import Button from '../Button';
import HubspotForm from '../Hubspot/hubspotForm.tsx';
import Logo from '../Logo';
import headerTypes, { headerDefaultProps } from '../../types/headerTypes';
import BrandBar from '../BrandBar/brandBar.tsx';

import SignUpForm from '../SignUpForm';

import eventModule from '../Event/eventModule.ts';

import './Header.scss';

class Header extends React.Component {
  static propTypes = headerTypes;

  static defaultProps = headerDefaultProps;

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      showForm: false,
      invert: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll = () => {
    const { scrollY } = window;
    const { invert } = this.state;
    if (scrollY > 0 && !invert) {
      this.setState({ invert: true });
    }
    if (scrollY === 0) {
      this.setState({ invert: false });
    }
    if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
      document.getElementById('flex-container').style.top = '0';
    } else {
      document.getElementById('flex-container').style.top = 'auto';
    }
  };

  toggleMenu = () => {
    const { open } = this.state;
    this.setState({
      open: !open,
    });
    if (open) {
      document.body.classList.remove('noScroll');
      document.body.classList.remove('modal');
    } else {
      document.body.classList.add('noScroll');
      document.body.classList.add('modal');
    }
  };

  toggleForm = () => {
    const { showForm } = this.state;
    this.setState({
      open: false,
      showForm: !showForm,
    });
    if (showForm) {
      document.body.classList.remove('noScroll');
      document.body.classList.remove('modal');
      eventModule.triggers.eventTrigger(
        eventModule.eventDictionary.stayUpdatedFormSubmit,
      );
    } else {
      document.body.classList.add('noScroll');
      document.body.classList.add('modal');
    }
  };

  navigateHome = () => {
    eventModule.triggers.eventTrigger(
      eventModule.eventDictionary.selectBetterButtonClick,
    );
    document.body.classList.remove('noScroll');
    document.body.classList.remove('modal');
    // window.scrollTo(0,0);
    this.setState({
      open: false,
    });
    navigate('/');
  };

  toProducts = () => {
    eventModule.triggers.eventTrigger(
      eventModule.eventDictionary.selectBetterProductsButtonClick,
    );
    document.body.classList.remove('noScroll');
    document.body.classList.remove('modal');
    // window.scrollTo(0,0);
    this.setState({
      open: false,
    });
    navigate('/products');
  };

  toFindUs = () => {
    eventModule.triggers.eventTrigger(
      eventModule.eventDictionary.findUsButtonClick,
    );
    document.body.classList.remove('noScroll');
    document.body.classList.remove('modal');
    // window.scrollTo(0,0);
    this.setState({
      open: false,
    });
    navigate('/find-us');
  };

  toAboutUs = () => {
    document.body.classList.remove('noScroll');
    document.body.classList.remove('modal');
    // window.scrollTo(0,0);
    this.setState({
      open: false,
    });
    navigate('/about-us');
  };

  toDelivery = () => {
    document.body.classList.remove('noScroll');
    document.body.classList.remove('modal');
    // window.scrollTo(0,0);
    this.setState({
      open: false,
    });
    navigate('/delivery');
  };

  render() {
    const { className, view, ...props } = this.props;
    const { open, invert, showForm } = this.state;
    const color = open || invert ? { color: 'black' } : { color: 'white' };
    const fillColor = view === 'about-us' ? 'white' : 'black';

    return (
      <Fragment>
        <BrandBar />
        <header
          id="flex-container"
          className={`${classNames('Header', className)}  ${
            invert ? ' invert' : ''
          } ${open ? ' open' : ''}`}
          {...props}
          style={view === 'home' && !invert ? { borderBottom: 0 } : {}}
        >
          <div
            className="Header--container container "
            style={{ maxHeight: '5px' }}
          >
            <div className="Header--rainbowStrip" />

            <div
              className={`Header--logo ${open ? ' open' : ''} ${
                invert ? ' invert' : ''
              }`}
              onClick={this.navigateHome}
              role="presentation"
              // style={{ width: '50px' }}
            >
              {/* <Logo
                className={`Header--nav ${invert ? ' invert' : ''} ${
                  open ? ' open' : ''
                }`}
              /> */}
              <svg
                className={`Header--logo ${invert ? ' invert' : ''} ${
                  open ? ' open' : ''
                }`}
                width="46px"
                height="46px"
                viewBox="0 0 46 46"
                // style={{ marginTop: 10 }}
              >
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g
                    id="logo"
                    transform="translate(-125.000000, -50.000000)"
                    fill={fillColor}
                  >
                    <g
                      id="Select-Logo-full"
                      transform="translate(125.000000, 50.000000)"
                    >
                      <g transform="translate(-0.000000, 0.000000)">
                        <path
                          id="icon"
                          fillRule="nonzero"
                          d="M18.0036049,31.4265185 L28.0668148,31.4265185 L28.0668148,34.6317531 L18.0036049,34.6317531 L18.0036049,31.4265185 Z M45.9840988,22.9500247 C45.9840988,35.604 35.6891852,45.8989136 23.0408889,45.8989136 C10.3925926,45.8989136 0.0851851852,35.604 0.0851851852,22.9500247 C0.0851851852,10.2960494 10.3823704,0 23.0352099,0 C35.6880494,0 45.9840988,10.2949136 45.9840988,22.9500247 Z M42.78,22.9500247 C42.78,12.0622222 33.9207407,3.20523457 23.0352099,3.20523457 C12.149679,3.20523457 3.29041975,12.0644938 3.29041975,22.9500247 C3.29041975,33.8355556 12.149679,42.693679 23.0352099,42.693679 C33.9207407,42.693679 42.78,33.8366914 42.78,22.9500247 L42.78,22.9500247 Z M24.4118025,18.0660741 C21.884642,17.5152099 20.5886914,17.2233086 20.5886914,16.0216296 C20.5886914,15.0164444 21.6574815,14.4315062 23.2453333,14.4315062 C25.0478519,14.4315062 26.4312593,15.1129877 27.2581235,16.0920494 L29.7784691,14.0328395 C28.4495802,12.3291358 26.4676049,11.224 23.4395556,11.224 C19.4222222,11.224 17.0245432,13.1355556 17.0245432,16.180642 C17.0245432,19.7459259 20.2320494,20.6852346 22.7592099,21.2031605 C25.1898272,21.7222222 26.3562963,22.0141235 26.3562963,23.2771358 C26.3562963,24.5083457 25.2204938,25.0274074 23.569037,25.0274074 C21.6279506,25.0274074 19.8526914,24.4186173 18.7986667,23.0034074 L16.2499259,25.0853333 C17.6424198,27.243358 20.3388148,28.2031111 23.3407407,28.2031111 C27.2297284,28.2031111 29.9181728,26.6129877 29.9181728,23.0511111 C29.9181728,19.4892346 27.1661235,18.6771358 24.4118025,18.0592593 L24.4118025,18.0660741 Z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>

            <nav
              className={`Header--nav ${open ? ' open' : ''} ${
                invert ? ' invert' : ''
              } `}
            >
              <Button
                className="Header--nav-cta"
                isTransparent
                {...color}
                onClick={this.toProducts}
              >
                Products
              </Button>
              <Button
                className="Header--nav-cta"
                isTransparent
                {...color}
                onClick={this.toAboutUs}
              >
                About Us
              </Button>
              <Button
                className="Header--nav-cta"
                isTransparent
                {...color}
                onClick={this.toFindUs}
              >
                Find Us
              </Button>
              <Button
                id="header-stay-updated-cta"
                className="Header--nav-cta"
                isTransparent
                {...color}
                onClick={this.toggleForm}
              >
                Stay Updated
              </Button>
              <Button
                id="header-delivery"
                className="Header--nav-cta"
                isTransparent
                {...color}
                onClick={this.toDelivery}
              >
                Delivery
              </Button>
              <Button
                id="header-merch"
                className="Header--nav-cta"
                isTransparent
                {...color}
                onClick={() => {
                  // eslint-disable-next-line no-unused-expressions
                  typeof window !== 'undefined'
                    ? window.open('https://merch.selectcannabis.com')
                    : navigate('https://merch.selectcannabis.com');
                }}
              >
                Merch
              </Button>
              {open && <div className="Header--rainbowStrip mobile" />}
            </nav>

            <div className="Header--mobile-menu">
              <Button
                className="Header--nav-cta"
                isTransparent
                onClick={this.toggleMenu}
                {...color}
              >
                {open ? 'Close' : 'Menu'}
              </Button>
            </div>
          </div>
        </header>
        {showForm && (
          <div className="Header--form">
            <div className="Header--form--container">
              <Button
                className="Header--form--container--close-cta"
                isTransparent
                // rollOverStyle="underline"
                color="black"
                onClick={this.toggleForm}
              >
                Close
              </Button>
              <h3 className="SignUpForm--title">
                <h2 style={{ marginBottom: '0.5em' }}>
                  <Logo className="signup-invert" />
                </h2>
                {/* eslint-disable-next-line max-len */}
                We're always working on something new. Sign up for our
                newsletter to stay up to date on all the latest brand and
                product news!
              </h3>
              <HubspotForm
                id="general"
                formId="cbffbe5c-0b75-4629-9d1d-50ae4e621153"
              />
              <SignUpForm />
            </div>
            <div className="Header--rainbowStrip" />
          </div>
        )}
      </Fragment>
    );
  }
}

export default Header;
